import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EndPointService } from "../communication/endpoint-service";
export interface IIntegrationAccount {
  id: number;
  providerAccountId: number;
  integrationAccountName: string;
  integrationType: string;
  userIntegrationName: string;
  date_created?: Date;
  date_updated?: Date;
  privacySetting: string;
}

export interface IIntegrationUpdate {
  id: number;
  name: string;
}

export interface IPrivateSettingReq {
  id: number;
  privacySetting: string;
}

@Injectable({
  providedIn: 'root'
})

export class IntegrationsAccountService {
    apiRoot: string;
    constructor(private endpointService: EndPointService, private _http: HttpClient) {
        this.apiRoot = this.endpointService.integrationsAccountEndpoint;
    }
    getIntegrationAccounts(): Observable<IIntegrationAccount[] | []> {
        return this._http.get(this.apiRoot).pipe(
          map((results:any) => { return results }),
          catchError((err) => {
            console.log("get intergrations for provider account failed");
            return throwError(err);
          })
        );
    }

    getYoutubeAuthURL(finalRedirectUrl: string): Observable<string> {
      const url = this.apiRoot + '/getYoutubeAuthURL';
      let httpParams:any = new HttpParams();
      httpParams = httpParams.append('finalRedirectUrl', finalRedirectUrl);
      return this._http.get(url, {params:httpParams}).pipe(
        map((results:any) => { return results }),
        catchError((err) => {
          console.log("get youtube auth url failed");
          return throwError(err);
        })
      );
    }

    updateIntegrationAccount(params: IIntegrationUpdate): Observable<IIntegrationAccount> {
      const url = this.apiRoot + '/update';
      return this._http.post(url, params).pipe(
        map((results:any) => { return results }),
        catchError((err) => {
          console.log("update integration account failed");
          return throwError(err);
        })
      );
    }
    //delete integration account based on goggle account id(eg:ydeepthi.comcast@gmail.com)
    deleteIntegrationAccount(googleAccountName: string) {
      const url = this.apiRoot + '/delete';
      const params = {'googleAccountName': googleAccountName}
      return this._http.post(url, params).pipe(
        map((results:any) => { return results }),
        catchError((err) => {
          console.log("delete integration account failed");
          return throwError(err);
        })
      );
    }

    //update privacy setting for youtube integration account.
    updatePrivacySetting(params: IPrivateSettingReq): Observable<IIntegrationAccount[]> {
      const url = this.apiRoot + '/privacyUpdate';
      return this._http.post(url, params).pipe(
        map((results:any) => { return results }),
        catchError((err) => {
          console.log("update privay setting for integration account failed");
          return throwError(err);
        })
      );
    }
}