import { Injectable } from '@angular/core';
// import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EndPointService } from "../communication/endpoint-service";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IGuidesResponse {
  apiUrl: string;
  displayOrder: number;
  docName: string;
  documentType: string;
  filename: string;
  filepath: string;
  id: number;
  userType: string;
  version: string;
}

export interface IGuidesRequest {
  docName?: string;
  userType: string;
}
@Injectable({
  providedIn: 'root'
})
export class GuidesService {
  apiRoot: string;
  constructor(
    private _http: HttpClient,
    private endpointService: EndPointService
  ) { 
    this.apiRoot = this.endpointService.guideEndPoint;
  }

  /**
   * getGuides accepts InputParams of type IGuidesRequest
   *  userType as string and docName as string which is optional
   * returns Observable of type IGuidesResponse
   */
  getGuides(inputParams:IGuidesRequest): Observable<IGuidesResponse[]> {
    let httpParams:any = new HttpParams();
    Object.keys(inputParams).forEach((index) => {
        const temp:any = inputParams[index as keyof IGuidesRequest];
        httpParams = httpParams.append(index, temp);
    });
    return this._http.get(this.apiRoot, {params:httpParams}).pipe(
      map((results:any) => { return results }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getGuideFile(id: number) {
    const url: string = this.apiRoot + `/${id}/file`;
    return this._http.get(url).pipe(
      map((data:any) => {
        let asset;
        let ab = new ArrayBuffer(data.fileData.data.length);
        let view = new Uint8Array(ab);
        for (let i = 0; i < data.fileData.data.length; ++i) {
            view[i] = data.fileData.data[i];
        }

        // Once we have an arraybuffer, we can convert that to a typed blob
        if (ab) {
            asset = new Blob([ab], {
                type: 'application/pdf',
            });
        }
        return {
            data: asset,
        };
      }),
      catchError((err) => {
        console.log(err, "get guide file failed");
        return throwError(err);
      })
    );
  }
}
