import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { downgradeInjectable } from "@angular/upgrade/static";
import { DeleteAgencyDialogComponent } from '../../settings/agencydetail/components/delete-agency-dialog/delete-agency-dialog.component';
import { UnableDeleteAgencyDialogComponent } from "../../settings/agencydetail/components/unable-delete-agency-dialog/unable-delete-agency-dialog.component";
declare var angular: angular.IAngularStatic;

export interface IDeleteAgencyReq {
    agency: number;
    advertisers: number[];
    brands: number[];
    campaigns: number[];
}
@Injectable({
    providedIn: 'root',
})
export class AgencyService { 
    constructor (public dialog: MatDialog,
    ) {
    }
    openDeleteAgencyModal (deleteAgencyReq: IDeleteAgencyReq) {
        this.dialog.open(DeleteAgencyDialogComponent, {
            width: '350px',
            data: {
                deleteAgencyReq: deleteAgencyReq
            }
        });
    }
    openUnableDeleteAgencyModal () {
        this.dialog.open(UnableDeleteAgencyDialogComponent, {
            width: '350px',
        });
    }

}
angular.module('comcast.common.communication').factory('AgencyService', downgradeInjectable(AgencyService));
